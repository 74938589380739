@import url(https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'LunaLand';
  src: local('LunaLand'), url(/static/media/lunaland.8590d339.woff) format('woff');
}

body {
  margin: 0;
  font-family:  'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#121212;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: '#4C4C4C';
}

a:link {
  color: var(--bright-red);
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
/* visited link */
a:visited {
  color: var(--dark-red);
}
/* mouse over link */
a:hover {
  color: var(--bright-red);
  cursor: pointer;
}
/* selected link */
a:active {
  color: var(--bright-red);
}

.goth {
  font-family: 'Cinzel Decorative', serif;
}

.gothLite{
  font-family: 'Cinzel', serif;
}


.cap {
  font-size: 88px;
}
:root {
  --yellow: #fccd35;
  --pink: #b507a3;
  --green: #00cc00;
  --white: #ffffff;
  --off-white: #181818;
  --light-grey: #b1b1b1;
  --dark-grey: #eeeeee;
  --black: #000000;
  --bright-red: #ff0000;
  --red: #be49b8;
  --dark-red: #ae1414;
  --red-red: #cb0000;
}


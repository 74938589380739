:root {
  --yellow: #fccd35;
  --pink: #b507a3;
  --green: #00cc00;
  --white: #ffffff;
  --off-white: #181818;
  --light-grey: #b1b1b1;
  --dark-grey: #eeeeee;
  --black: #000000;
  --bright-red: #ff0000;
  --red: #be49b8;
  --dark-red: #ae1414;
  --red-red: #cb0000;
}
